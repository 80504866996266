import React from "react"
import PropTypes from "prop-types"
import { Grid, Box, Typography } from "@mui/material"

import { Container, Section, HammerBreak } from "../Layout"
import { Hero } from "../Hero"

import { StyledHome } from "./Home.styled"

import HeroHome from "../../images/hero-home.svg"
import IconShadowfiend from "../../images/icon-shadowfiend.svg"
import IconMapbg from "../../images/icon-mapbg.svg"

const Home = ({ children }) => {
  return (
    <>
      <StyledHome>
        <Box>
          <Hero>
            <Container>
              <Box>
                <Grid container>
                  <Grid item md={2}></Grid>
                  <Grid item md={8}>
                    <Section>
                      <Box
                        display="block"
                        component="img"
                        src={HeroHome}
                        alt="bos logo"
                        mb={2}
                        align="center"
                        sx={{ maxWidth: `300px`, margin: `0 auto` }}
                      />
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        align="center"
                        gutterBottom
                      >
                        The story of four adventurers that unlock ancient powers
                      </Typography>
                    </Section>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
              </Box>
            </Container>
          </Hero>
        </Box>
        <Box>
          <Container>
            <Section>
              <Box display="flex" justifyContent="center">
                <Box
                  sx={{
                    position: `relative`,
                    height: `486px`,
                    width: `588px`,
                  }}
                >
                  <Box sx={{ position: `relative`, zIndex: `100` }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      aligItems="center"
                      justifyContent="center"
                      sx={{ height: `486px` }}
                    >
                      <Typography variant="h4" color="secondary" align="center">
                        BASED IN THE LAND OF <br />
                        YRUB SILAS
                      </Typography>

                      <Typography
                        paragraph
                        color="textSecondary"
                        align="center"
                      >
                        They explore the land of Aliak in the material plane of
                        Yrub Silas. They form an alliance - a fellowship that
                        blurs the line between good and evil and become caught
                        in fulfilling or preventing an ancient prophecy.
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    component="img"
                    src={IconMapbg}
                    alt="map"
                    sx={{
                      position: `absolute`,
                      zIndex: `99`,
                      top: `0`,
                      left: `0`,
                      height: `100%`,
                      width: `100%`,
                    }}
                  />
                </Box>
              </Box>
            </Section>
            <HammerBreak />
            <Section>
              <Box display="flex" justifyContent="center">
                <Box
                  sx={{
                    position: `relative`,
                    height: `502px`,
                    width: `425px`,
                  }}
                >
                  <Box sx={{ position: `relative`, zIndex: `100` }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      aligItems="center"
                      justifyContent="center"
                      sx={{ height: `500px` }}
                    >
                      <Typography variant="h4" color="secondary" align="center">
                        CURSE OF THE SHADOW FIEND
                      </Typography>

                      <Typography
                        paragraph
                        color="textSecondary"
                        align="center"
                      >
                        <i>
                          "Whispers and silhouettes begin to reveal themselves.
                          The cobbled streets become deserted and something
                          inhuman faintly howls throughout the swamps and
                          forests…"
                        </i>
                      </Typography>
                      <Typography
                        paragraph
                        color="textSecondary"
                        align="center"
                      >
                        Curse of the Shadow Fiend is the follow up the module,
                        Tomb of Helio. Based on homebrew D&D 5e.
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    component="img"
                    src={IconShadowfiend}
                    alt="shadowfiend"
                    sx={{
                      position: `absolute`,
                      zIndex: `99`,
                      top: `0`,
                      left: `0`,
                      height: `100%`,
                      width: `100%`,
                    }}
                  />
                </Box>
              </Box>
            </Section>
          </Container>
        </Box>
      </StyledHome>
    </>
  )
}

Home.propTypes = {
  children: PropTypes.node,
}

export default Home
